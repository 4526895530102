import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'millisecondsToDuration',
})
export class MillisecondsToDurationPipe implements PipeTransform {
    transform(valueInMilliseconds: string): string {
        const millisecondsPerSecond = 1000;
        const secondsPerMinute = 60;
        const secondsPerHour = 60 * 60;

        let milliseconds = Number(valueInMilliseconds);
        if (isNaN(milliseconds)) {
            throw new TypeError(`Input ${valueInMilliseconds} cannot be cast to Number.`);
        }

        let remainingSeconds = Math.floor(milliseconds / millisecondsPerSecond);
        const hours = Math.floor(remainingSeconds / secondsPerHour)
            .toString()
            .padStart(2, '0');
        remainingSeconds = remainingSeconds % secondsPerHour;
        const minutes = Math.floor(remainingSeconds / secondsPerMinute)
            .toString()
            .padStart(2, '0');
        const seconds = (remainingSeconds % secondsPerMinute).toString().padStart(2, '0');
        return `${hours}.${minutes}.${seconds}`;
    }
}
