import { Injectable } from '@angular/core';
import { of, switchMap, catchError, map } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import {
    getDetailItemInformation,
    getDetailItemNavInformation,
    getDetailItemSequence,
    notifyOfDetailError,
    setDetailItemInformation,
    setDetailItemNavInformation,
    setDetailItemSequence,
    getDetailItemSubtitleTranscript,
    setDetailItemSubtitleTranscript,
    getDetailProgramSubtitleTranscript,
    setDetailProgramSubtitleTranscript,
    getDetailProgramSequence,
    setDetailProgramSequence,
    getDetailProgramInformation,
    setDetailProgramInformation,
    getDetailProgramNavInformation,
    setDetailProgramNavInformation,
    getDetailProgramMusicInformation,
    setDetailProgramMusicInformation,
    getDetailItemMusicInformation,
    setDetailItemMusicInformation,
    setEnvelopeData,
    notifyOfEnvelopeError,
    setLightTableData,
    getEnvelopeData,
    getLightTable,
    setDetailMediaCuts,
    getDetailMediaCuts,
} from './details.actions';
import {
    toTypedItemInformationModel,
    toTypedLightTableKeyFrameDto,
    toTypedProgramInformationModel,
    toTypedSequenceMetadataDto,
    toTypedSubtitleTranscriptMetadataDto,
} from '../shared/typed_metadata.model';
import {
    DetailsService,
    EnvelopeDto,
    EnvelopeService,
    ItemInfoMetadataDto,
    LighttableKeyFrameDto,
    LighttableService,
    MediaCutDto,
    MediaCutService,
    MusicMetadataDto,
    MusicService,
    ProgramInfoMetadataDto,
    SequenceMetadataDto,
    SubtitleTranscriptMetadataDto,
    SubtitleTranscriptService,
    NavigationMetadataDto,
} from '@faro/metadata-angular-client';

@Injectable()
export class DetailsEffects {
    constructor(
        private readonly metadataService: DetailsService,
        private readonly mediaCutService: MediaCutService,
        private readonly envelopeService: EnvelopeService,
        private readonly musicService: MusicService,
        private readonly subtitleTranscriptService: SubtitleTranscriptService,
        private readonly lightTableService: LighttableService,
        private readonly actions$: Actions
    ) {}

    getDetailItemNavInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailItemNavInformation),
            switchMap(({ programId, itemId }) => {
                return this.metadataService.detailsGetItemNavigation(programId, itemId).pipe(
                    map((res: NavigationMetadataDto) => {
                        return setDetailItemNavInformation({ detailNavInformation: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );

    getDetailItemInformationTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailItemInformation),
            switchMap(({ programId, itemId }) => {
                return this.metadataService.detailsGetItemInfo(programId, itemId).pipe(
                    map((res: ItemInfoMetadataDto) => {
                        return setDetailItemInformation({ contentInformation: toTypedItemInformationModel(res) });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of();
                    })
                );
            })
        )
    );

    getDetailItemSequenceTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailItemSequence),
            switchMap(({ programId, itemId }) => {
                return this.metadataService.detailsGetItemSequences(programId, itemId).pipe(
                    map((res: SequenceMetadataDto[]) => {
                        return setDetailItemSequence({ contentSequence: toTypedSequenceMetadataDto(res) });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of();
                    })
                );
            })
        )
    );

    getDetailItemSubtitleTranscriptTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailItemSubtitleTranscript),
            switchMap(({ programId, itemId, mediaCut }) => {
                return this.subtitleTranscriptService
                    .subtitleTranscriptGetItemSubtitlesTranscripts(programId, itemId, mediaCut)
                    .pipe(
                        map((res: SubtitleTranscriptMetadataDto[]) => {
                            return setDetailItemSubtitleTranscript({
                                contentSubtitleTranscript: toTypedSubtitleTranscriptMetadataDto(res),
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of();
                        })
                    );
            })
        )
    );

    getDetailItemMusicInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailItemMusicInformation),
            switchMap(({ programId, itemId }) => {
                return this.musicService.musicGetItemMetadata(programId, itemId).pipe(
                    map((res: MusicMetadataDto[]) => {
                        return setDetailItemMusicInformation({ musicInformation: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );

    getDetailProgramNavInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailProgramNavInformation),
            switchMap(({ programId }) => {
                return this.metadataService.detailsGetProgramNavigation(programId).pipe(
                    map((res: NavigationMetadataDto) => {
                        return setDetailProgramNavInformation({ detailNavInformation: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );

    getDetailProgramInformationTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailProgramInformation),
            switchMap(({ programId }) => {
                return this.metadataService.detailsGetProgramInfo(programId).pipe(
                    map((res: ProgramInfoMetadataDto) => {
                        return setDetailProgramInformation({ contentInformation: toTypedProgramInformationModel(res) });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of();
                    })
                );
            })
        )
    );

    getDetailProgramSequenceTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailProgramSequence),
            switchMap(({ programId }) => {
                return this.metadataService.detailsGetProgramSequences(programId).pipe(
                    map((res: SequenceMetadataDto[]) => {
                        return setDetailProgramSequence({ contentSequence: toTypedSequenceMetadataDto(res) });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of();
                    })
                );
            })
        )
    );

    getDetailProgramSubtitleTranscriptTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailProgramSubtitleTranscript),
            switchMap(({ programId, mediaCut }) => {
                return this.subtitleTranscriptService
                    .subtitleTranscriptGetProgramSubtitlesTranscripts(programId, mediaCut)
                    .pipe(
                        map((res: SubtitleTranscriptMetadataDto[]) => {
                            return setDetailProgramSubtitleTranscript({
                                contentSubtitleTranscript: toTypedSubtitleTranscriptMetadataDto(res),
                            });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of();
                        })
                    );
            })
        )
    );

    getDetailProgramMusicInformation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailProgramMusicInformation),
            switchMap(({ programId }) => {
                return this.musicService.musicGetProgramMetadata(programId).pipe(
                    map((res: MusicMetadataDto[]) => {
                        return setDetailProgramMusicInformation({ musicInformation: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );

    getMediaCuts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getDetailMediaCuts),
            switchMap(({ programId, itemId }) => {
                return this.mediaCutService.mediaCutFindPlayableMediaCuts(programId, itemId).pipe(
                    map((mediaCuts: MediaCutDto[]) => {
                        return setDetailMediaCuts({ mediaCuts });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );

    getEnvelopeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getEnvelopeData),
            switchMap(({ mediaCutId, programId, itemId, vtcFrom, vtcTo }) => {
                return this.envelopeService.envelopeGetMetadata(mediaCutId, programId, vtcFrom, vtcTo, itemId).pipe(
                    map((res: EnvelopeDto) => {
                        return setEnvelopeData({ envelopeData: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfEnvelopeError({ errorResponse }));
                    })
                );
            })
        )
    );

    getLightTableData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getLightTable),
            switchMap(({ mediaCutId, programId, itemId }) => {
                return this.lightTableService.lighttableGetKeyframes(mediaCutId, programId, itemId).pipe(
                    map((res: LighttableKeyFrameDto[]) => {
                        return setLightTableData({ lightTableData: toTypedLightTableKeyFrameDto(res) });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDetailError({ errorResponse }));
                    })
                );
            })
        )
    );
}
