import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import {
    activateShoppingCart,
    createNewShoppingCart,
    doNotShowNewCartDialog,
    duplicateShoppingCart,
    orderActiveShoppingCart,
    searchShoppingCarts,
    getActiveShoppingCart,
    updateShoppingCartSearchOrderBy,
    updateShoppingCartSearchOrderDirection,
    setActiveShoppingCart,
    notifyOfGetActiveShoppingCartError,
    renameActiveShoppingCart,
    changeActiveShoppingCartEntryPosition,
    notifyOfSaveShoppingCartInformationError,
    setActiveShoppingCartEntryRemark,
    notifyOfCreateShoppingCartError,
    notifyOfShoppingCartOrderError,
    notifyOfDuplicateShoppingCartError,
    notifyOfActivateShoppingCartError,
    notifyOfSearchShoppingCartError,
    addItemToActiveShoppingCart,
    getAvailableMaterial,
    setAvailableMaterial,
    setSelectedMaterial,
    deleteActiveShoppingCartEntry,
    callSuccessAction,
    setEntryErrorMessages,
    getSelectedShoppingCart,
    setSelectedShoppingCart,
    notifyOfGetSelectedShoppingCartError,
    getAdmiraDevices,
    setAdmiraDevices,
    getAdmiraFolders,
    setAdmiraFolders,
    setShoppingCartSearchResponse,
    initialSearchShoppingCarts,
    changeActiveShoppingCartProductionId,
    getUserExportTarget,
    setSelectedAdmiraDevice,
    storeSelectedAdmiraDevice,
    notifyOfSuccessfullyAddedItem,
    notifyOfItemAlreadyExists,
    getNewActiveShoppingCart,
    setNewActiveShoppingCart,
    notifyOfAdmiraDeviceError,
    changeActiveShoppingCartFassungsId,
} from './shopping-cart.actions';
import { Store } from '@ngrx/store';
import { catchError, filter, map, mergeMap, of, Subject, switchMap, take, tap } from 'rxjs';
import {
    selectActiveShoppingCart,
    selectExportSettings,
    selectSelectedAdmiraDevice,
    selectShoppingCartSearchOptions,
} from './shopping-cart.selectors';
import {
    ActiveShoppingCartService,
    AdmiraDeviceDto,
    ShoppingCartService,
    OrderService,
    SearchParameterDto,
    ShoppingCartEntryService,
    ShoppingCartSearchService,
    AdmiraBrowserService,
    AdmiraFolderResponse,
    AddShoppingCartEntryResultDto,
    OrderRequestDto,
} from '@faro/order-angular-client';
import { HttpErrorResponse } from '@angular/common/http';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { ShoppingCartSearchOptions } from './shopping-cart.state';
import * as dayjs from 'dayjs';
import { getSortDirection, getSortValueField } from './shopping-cart.mapper';
import { fromMilliseconds } from '../../shared/duration';
import { MediaCutDto, MediaCutService } from '@faro/metadata-angular-client';
import { UserSetting, UserSettingsService } from '@faro/profile-angular-client';
import { RestrictedContentDialogComponent } from '../../shared/dialogs/restricted-content-dialog/restricted-content-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { selectUserId } from '../../state/user.selectors';
import { OrderLimitDialogComponent } from '../../shared/dialogs/order-limit-dialog/order-limit-dialog.component';

@Injectable()
export class ShoppingCartEffects implements OnDestroy {
    private _destroyed$ = new Subject<void>();

    constructor(
        private readonly actions$: Actions,
        private store: Store,
        private router: Router,
        private readonly activeShoppingCartService: ActiveShoppingCartService,
        private readonly shoppingCartService: ShoppingCartService,
        private readonly orderShoppingCartService: OrderService,
        private readonly shoppingCartEntryService: ShoppingCartEntryService,
        private readonly shoppingCartSearchService: ShoppingCartSearchService,
        private readonly admiraBrowserService: AdmiraBrowserService,
        private readonly mediaCutService: MediaCutService,
        private readonly userSettingsService: UserSettingsService,
        private readonly dialogService: DialogService
    ) {}

    getActiveShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getActiveShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    return this.activeShoppingCartService.activeShoppingCartGetOrCreate(userId).pipe(
                        map((res: ShoppingCartDto) => {
                            return setActiveShoppingCart({ activeShoppingCart: res });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfGetActiveShoppingCartError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    getNewActiveShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getNewActiveShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    return this.activeShoppingCartService.activeShoppingCartGetOrCreate(userId).pipe(
                        map((res: ShoppingCartDto) => {
                            return setNewActiveShoppingCart({ activeShoppingCart: res });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfGetActiveShoppingCartError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    setSelectedShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getSelectedShoppingCart),
            switchMap(({ cartId }) => {
                return this.shoppingCartService.shoppingCartGet(cartId).pipe(
                    map((res: ShoppingCartDto) => {
                        return setSelectedShoppingCart({ selectedShoppingCart: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfGetSelectedShoppingCartError({ errorResponse }));
                    })
                );
            })
        )
    );

    renameActiveShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(renameActiveShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectActiveShoppingCart).pipe(
                    filter(v => !!v),
                    map(v => v as ShoppingCartDto)
                )
            ),
            switchMap(([{ name }, cart]) => {
                return this.shoppingCartService.shoppingCartRename(cart.id, JSON.stringify(name)).pipe(
                    map((res: ShoppingCartDto) => {
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                    })
                );
            })
        )
    );

    changeActiveShoppingCartProductionId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeActiveShoppingCartProductionId),
            concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
            switchMap(([{ pid }, cart]) => {
                return this.shoppingCartService.shoppingCartSetProductionId(cart.id, JSON.stringify(pid)).pipe(
                    map((res: ShoppingCartDto) => {
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                    })
                );
            })
        )
    );

    changeActiveShoppingCartFassungsId$ = createEffect(() =>
      this.actions$.pipe(
        ofType(changeActiveShoppingCartFassungsId),
        concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
        switchMap(([{ fid }, cart]) => {
          return this.shoppingCartService.shoppingCartSetFassungsId(cart.id, JSON.stringify(fid)).pipe(
            map((res: ShoppingCartDto) => {
              return callSuccessAction();
            }),
            catchError((errorResponse: HttpErrorResponse) => {
              return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
            })
          );
        })
      )
    );

    orderActiveShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(orderActiveShoppingCart),
            concatLatestFrom(() => [
                getActiveShoppingCartFromStore(this.store),
                getSelectedAdmiraDevice(this.store),
                getExportSettings(this.store),
            ]),
            switchMap(([_, cart, admiraDevice, exportSettings]) => {
                const params: OrderRequestDto = {
                    shoppingCartId: cart.id,
                    exportPath: admiraDevice.devicePath,
                    exportDeviceId: admiraDevice.id,
                    exportDate: exportSettings.exportDate.toISOString(),
                    notificationEmail: exportSettings.eMail,
                };
                return this.orderShoppingCartService.orderOrderShoppingCart(params).pipe(
                    map((res: any) => {
                        this.store.dispatch(getActiveShoppingCart());
                        this.router.navigate(['shopping-cart/success']).then();
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        if (errorResponse.status === 400) {
                            this.store.dispatch(
                                setEntryErrorMessages({ cartEntryErrors: errorResponse.error.cartEntryErrors })
                            );
                        }
                        return of(notifyOfShoppingCartOrderError({ errorResponse }));
                    })
                );
            })
        )
    );

    duplicateShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(duplicateShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([{ cartId }, userId]) => {
                return this.shoppingCartService.shoppingCartDuplicate(cartId, { userId: userId }).pipe(
                    map((res: any) => {
                        this.router.navigate(['shopping-cart/active']).then();
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfDuplicateShoppingCartError({ errorResponse }));
                    })
                );
            })
        )
    );

    activateShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(activateShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([{ cartId }, userId]) => {
                return this.shoppingCartService.shoppingCartActivate(cartId, { userId: userId }).pipe(
                    map((res: any) => {
                        this.router.navigate(['shopping-cart/active']).then();
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfActivateShoppingCartError({ errorResponse }));
                    })
                );
            })
        )
    );

    createNewShoppingCart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createNewShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                return this.shoppingCartService.shoppingCartCreateNew({ userId: userId }).pipe(
                    map((res: any) => {
                        this.router.navigate(['shopping-cart/active']).then();
                        return getNewActiveShoppingCart();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfCreateShoppingCartError({ errorResponse }));
                    })
                );
            })
        )
    );

    changeActiveShoppingCartEntryPosition$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeActiveShoppingCartEntryPosition),
            concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
            switchMap(([{ entryIds }, cart]) => {
                return this.shoppingCartEntryService
                    .shoppingCartEntrySetPositionOfShoppingCartEntries(cart.id, entryIds)
                    .pipe(
                        map((res: any) => {
                            return callSuccessAction();
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                        })
                    );
            })
        )
    );

    setActiveShoppingCartEntryRemark$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveShoppingCartEntryRemark),
            concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
            switchMap(([{ entryId, note }, cart]) => {
                return this.shoppingCartEntryService.shoppingCartEntrySetRemark(cart.id, entryId, note).pipe(
                    map((res: any) => {
                        return callSuccessAction();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                    })
                );
            })
        )
    );

    deleteShoppingCartEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActiveShoppingCartEntry),
            concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
            switchMap(([{ entryId }, cart]) => {
                return this.shoppingCartEntryService.shoppingCartEntryRemoveEntry(cart.id, entryId).pipe(
                    map((res: any) => {
                        return getActiveShoppingCart();
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                    })
                );
            })
        )
    );

    getEntryMaterial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAvailableMaterial),
            switchMap(({ programId, itemId, sequenceId, selectionVtcIn, selectionVtcOut }) => {
                return this.mediaCutService
                    .mediaCutFindOrderableMediaCuts(programId, itemId, sequenceId, selectionVtcIn, selectionVtcOut)
                    .pipe(
                        map((res: MediaCutDto[]) => {
                            return setAvailableMaterial({ material: res });
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                        })
                    );
            })
        )
    );

    setSelectedMaterial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setSelectedMaterial),
            concatLatestFrom(() => getActiveShoppingCartFromStore(this.store)),
            switchMap(([{ entryId, materialIds }, cart]) => {
                return this.shoppingCartEntryService
                    .shoppingCartEntrySetMaterialSelection(cart.id, entryId, materialIds)
                    .pipe(
                        map(res => {
                            return getActiveShoppingCart();
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return of(notifyOfSaveShoppingCartInformationError({ errorResponse }));
                        })
                    );
            })
        )
    );

    searchShoppingCarts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                searchShoppingCarts,
                initialSearchShoppingCarts,
                updateShoppingCartSearchOrderDirection,
                updateShoppingCartSearchOrderBy
            ),
            switchMap(_ => {
                return this.store.select(selectShoppingCartSearchOptions).pipe(
                    take(1),
                    mergeMap(searchOptions => {
                        const searchParams = createShoppingCartSearchRequest(searchOptions);
                        return this.shoppingCartSearchService.shoppingCartSearchSearchShoppingCarts(searchParams).pipe(
                            map(res => {
                                return setShoppingCartSearchResponse({ searchResult: res });
                            }),
                            catchError((errorResponse: HttpErrorResponse) => {
                                return of(notifyOfSearchShoppingCartError({ errorResponse }));
                            })
                        );
                    })
                );
            })
        )
    );

    addItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addItemToActiveShoppingCart),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([{ params }, userId]) => {
                return this.shoppingCartEntryService.shoppingCartEntryAddItemToActiveCart(userId!, params).pipe(
                    map((res: AddShoppingCartEntryResultDto) => {
                        switch (res) {
                            case AddShoppingCartEntryResultDto.EntryCreated:
                                this.store.dispatch(getActiveShoppingCart());
                                return notifyOfSuccessfullyAddedItem();
                            case AddShoppingCartEntryResultDto.EntryAlreadyExists:
                                return notifyOfItemAlreadyExists();
                            case AddShoppingCartEntryResultDto.EntryNotCreatedDueToUserRestrictions:
                                const ref = this.dialogService.open(RestrictedContentDialogComponent, {
                                    showHeader: false,
                                    closeOnEscape: true,
                                    dismissableMask: true,
                                    data: { title: 'Gesperrtes Material' },
                                });

                                ref.onClose.pipe(take(1)).subscribe(data => {
                                    if (data) {
                                        const ignoreRestrictionParams = {
                                            ...params,
                                            ignoreUserRestrictions: true,
                                        };
                                        this.store.dispatch(
                                            addItemToActiveShoppingCart({ params: ignoreRestrictionParams })
                                        );
                                    }
                                });
                                return callSuccessAction();
                            case AddShoppingCartEntryResultDto.ShoppingCartSizeLimitExceeded:
                                const limitDialog = this.dialogService.open(OrderLimitDialogComponent, {
                                    showHeader: false,
                                    closeOnEscape: true,
                                    dismissableMask: true,
                                    data: { title: 'Warenkorb Limit überschritten' },
                                });

                                limitDialog.onClose.pipe(take(1)).subscribe(data => {
                                    if (data) {
                                        this.store.dispatch(
                                            addItemToActiveShoppingCart({
                                                params: {
                                                    ...params,
                                                    ignoreSizeLimit: true,
                                                },
                                            })
                                        );
                                    }
                                });
                                return callSuccessAction();
                            default:
                                return callSuccessAction();
                        }
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfSearchShoppingCartError({ errorResponse }));
                    })
                );
            })
        )
    );

    doNotDisplayNewShoppingCartDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(doNotShowNewCartDialog),
                tap(_ => {
                    localStorage.setItem('noShoppingCartDialog', 'true');
                })
            ),
        { dispatch: false }
    );

    getAdmiraDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAdmiraDevices),
            switchMap(() => {
                return this.admiraBrowserService.admiraBrowserGetAdmiraDevices().pipe(
                    map((res: AdmiraDeviceDto[]) => {
                        return setAdmiraDevices({ admiraDevices: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfAdmiraDeviceError({ errorResponse }));
                    })
                );
            })
        )
    );

    getAdmiraFolders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAdmiraFolders),
            switchMap(({ deviceId, path }) => {
                return this.admiraBrowserService.admiraBrowserGetAdmiraFolders(deviceId, path).pipe(
                    map((res: AdmiraFolderResponse) => {
                        return setAdmiraFolders({ admiraFolders: res });
                    }),
                    catchError((errorResponse: HttpErrorResponse) => {
                        return of(notifyOfAdmiraDeviceError({ errorResponse }));
                    })
                );
            })
        )
    );

    getUserExportTarget$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserExportTarget),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([_, userId]) => {
                if (userId) {
                    const key = 'ArMen.ShoppingCart.ExportTarget';
                    return this.userSettingsService.userSettingsGet(userId, key).pipe(
                        map((res: any) => {
                            try {
                                const device = JSON.parse(res.value);
                                const exportTarget: AdmiraDeviceDto = {
                                    id: device.id ?? '',
                                    devicePath: device.devicePath ?? '',
                                    deviceName: device.deviceName ?? '',
                                    ftpAccount: '',
                                };
                                const rootPath = device.rootPath;
                                const rootName = device.rootName;
                                return setSelectedAdmiraDevice({
                                    selectedAdmiraDevice: exportTarget,
                                    rootPath: rootPath,
                                    rootName: rootName,
                                });
                            } catch {
                                return callSuccessAction();
                            }
                        }),
                        catchError((errorResponse: HttpErrorResponse) => {
                            return errorResponse.status === 404
                                ? of()
                                : of(notifyOfGetActiveShoppingCartError({ errorResponse }));
                        })
                    );
                }
                return of();
            })
        )
    );

    storeUserExportTarget$ = createEffect(() =>
        this.actions$.pipe(
            ofType(storeSelectedAdmiraDevice),
            concatLatestFrom(() =>
                this.store.select(selectUserId).pipe(
                    filter(v => !!v),
                    map(v => v)
                )
            ),
            switchMap(([{ selectedAdmiraDevice, rootPath, rootName }, userId]) => {
                if (userId) {
                    const param: UserSetting = {
                        key: 'ArMen.ShoppingCart.ExportTarget',
                        value: JSON.stringify({
                            ...selectedAdmiraDevice,
                            rootPath: rootPath,
                            rootName: rootName,
                        }),
                        valueType: 'String',
                    };
                    return this.userSettingsService.userSettingsStore(userId, param).pipe(
                        map(res => {
                            return callSuccessAction();
                        })
                    );
                }
                return of();
            })
        )
    );

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}

function createShoppingCartSearchRequest(searchOptions: ShoppingCartSearchOptions): SearchParameterDto {
    return {
        name: searchOptions.description,
        number: searchOptions.number,
        ownerFirstName: searchOptions.firstName,
        ownerLastName: searchOptions.lastName,
        productionId: searchOptions.pid,
        creationDateFrom: getDateAsString(searchOptions.queryDate.from),
        creationDateTo: getDateAsString(searchOptions.queryDate.to),
        sortField: getSortValueField(searchOptions.sorting.sortBy),
        sortOrder: getSortDirection(searchOptions.sorting.sortDirection),
        status: searchOptions.orderStatus || undefined,
    };
}

function getDateAsString(date: Date | null): string | null {
    return dayjs(date).format('YYYY-MM-DD') !== 'Invalid Date' ? dayjs(date).format('YYYY-MM-DD') : null;
}

function calcVtcForRequest(vtc: number): string {
    return fromMilliseconds(vtc * 1000)
        .format('HH:mm:ss.ms')
        .toString();
}

function getActiveShoppingCartFromStore(store: Store) {
    return store.select(selectActiveShoppingCart).pipe(
        filter(v => !!v),
        map(v => v as ShoppingCartDto)
    );
}

function getSelectedAdmiraDevice(store: Store) {
    return store.select(selectSelectedAdmiraDevice).pipe(
        filter(v => !!v),
        map(v => v as AdmiraDeviceDto)
    );
}

function getExportSettings(store: Store) {
    return store.select(selectExportSettings).pipe(
        filter(v => !!v),
        map(v => v)
    );
}
