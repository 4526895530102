import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationDialogService } from '../../../shared/services/confirmation-dialog.service';
import { Store } from '@ngrx/store';
import { clearHoveredTimeFrame, setHoveredTimeFrame } from '../../details-state/details.actions';
import {
    selectAudioLabel,
    selectDetailMediaSelection,
    selectDetailSequence,
    selectLoadingSequenceTab,
} from '../../details-state/details.selectors';
import { Observable, of, Subject, takeUntil, catchError } from 'rxjs';
import { TimeService } from '../../shared/services/time.service';
import { parseTimeSpan } from '../../../shared/timespan';
import { Duration } from '../../../shared/duration';
import { TypedSequenceMetadataDto } from '../../shared/typed_metadata.model';
import { KeyframesService, SequenceMetadataDto } from '@faro/metadata-angular-client';
import { StoreUtilsService } from '../../../state/store-utils.service';
import {
    OrderRemarkDialogContentAndConfig,
    OrderRemarkResponse,
} from '../../../shared/dialogs/order-remark-dialog/order-remark-dialog-content-and.config';
import { focusElement } from '../../../shared/focus-element.helper';
import { addItemToActiveShoppingCart } from '../../../order/shopping-cart-state/shopping-cart.actions';
import { OrderParamService } from '../../shared/services/order-param.service';
import { AddShoppingCartEntryRequestDto } from '@faro/order-angular-client';
import { accessibilityDurationString } from '../../../shared/accessibility-duration.helper';
import { DialogService } from 'primeng/dynamicdialog';
import { RestrictionDialogComponent } from './restriction-dialog/restriction-dialog.component';

@Component({
    selector: 'app-sequence-content',
    templateUrl: './sequence-content.component.html',
    styleUrls: ['./sequence-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceContentComponent implements OnInit, OnDestroy {
    @Input()
    itemId: string | undefined;

    @Input()
    programId: string = '';

    @Input()
    contentType: string = '';

    @Input()
    readonly: boolean = false;

    @Input()
    keywords: string[] = [];

    loadingSequenceTab$: Observable<boolean>;

    currentVtc$: Observable<Duration | null>;

    highlightedRowIndex: number | undefined;

    activeSequenceId: string = '';

    tableHeader = [
        { value: 'Medien', scssClass: 'media-header' },
        { value: 'Land,Ort', scssClass: 'country-header' },
        { value: 'Beschreibung', scssClass: 'description-header' },
        { value: '', scssClass: 'value-header' },
        { value: 'Verwendung', scssClass: 'usage-header' },
    ];

    tableRows: TypedSequenceMetadataDto[] = [];

    private _destroyed$ = new Subject<void>();

    constructor(
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly keyframeService: KeyframesService,
        private store: Store,
        private readonly storeUtils: StoreUtilsService,
        private readonly orderParamService: OrderParamService,
        private timeService: TimeService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone,
        private dialogService: DialogService
    ) {
        this.loadingSequenceTab$ = this.store.select(selectLoadingSequenceTab);
        this.currentVtc$ = this.timeService.currentVtc$;
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => {
            this.timeService.currentVtc$.pipe(takeUntil(this._destroyed$)).subscribe(currentVtc => {
                const sequenceEntry = this.tableRows[this.getActiveSequenceId(currentVtc)];
                const activeSequenceId = sequenceEntry?.sequenceId || '';
                if (this.activeSequenceId !== activeSequenceId) {
                    this.activeSequenceId = activeSequenceId;
                    this.cdr.detectChanges();
                }
            });
        });

        // Notice that getting the sequences (actions getDetailProgramSequence/getDetailItemSequence)
        // is handled by DetailContentComponent as it needs sequence information, too.
        this.store
            .select(selectDetailSequence)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((content: TypedSequenceMetadataDto[] | undefined) => {
                this.tableRows = (content || []).map(s => ({
                    ...s,
                    duration: s.length !== null ? parseTimeSpan(s.length) : null,
                    keyframeBlob$: this.getKeyframeBlob(s.keyframeId),
                }));
            });
    }

    ngOnDestroy() {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    setHoveredTimeFrame(sequence: SequenceMetadataDto) {
        if (sequence.vtcIn !== null && sequence.vtcOut !== null) {
            this.store.dispatch(
                setHoveredTimeFrame({
                    hoveredVtcIn: parseTimeSpan(sequence.vtcIn),
                    hoveredVtcOut: parseTimeSpan(sequence.vtcOut),
                })
            );
        }
    }

    clearHoveredTimeFrame(): void {
        this.store.dispatch(clearHoveredTimeFrame());
    }

    getActiveSequenceId(currentVtc: Duration | null): number {
        if (!currentVtc) return -1;

        const currentVtcMs = currentVtc.asMilliseconds();

        return this.tableRows.findIndex((sequence: TypedSequenceMetadataDto) => {
            return (
                sequence.vtcInDuration !== null &&
                currentVtcMs >= sequence.vtcInDuration.asMilliseconds() &&
                sequence.vtcOutDuration !== null &&
                currentVtcMs <= sequence.vtcOutDuration.asMilliseconds()
            );
        });
    }

    showActions(event: Event, index: number): void {
        event.stopPropagation();
        this.highlightedRowIndex = index;
    }

    navigateToSequence(sequence: TypedSequenceMetadataDto): void {
        if (sequence.vtcIn !== null) {
            const vtcIn = parseTimeSpan(sequence.vtcIn);
            try {
                this.timeService.jumpToVtc(vtcIn);
            } catch (_) {
                // silently ignore this error - might occur if the current media cut does not "contain" all sequences
            }
        }
    }

    showConstraintDialogLink(sequence: TypedSequenceMetadataDto) {
        return sequence.rights || sequence.usageConstraints || sequence.usageLimitation || sequence.licenseHolder;
    }

    openConstraintsDialog(event: Event, sequence: TypedSequenceMetadataDto) {
        event.stopPropagation();
        this.dialogService.open(RestrictionDialogComponent, {
            showHeader: false,
            closeOnEscape: true,
            dismissableMask: true,
            styleClass: 'restriction-dialog',
            data: {
                title: 'Rechte',
                rights: sequence.rights,
                licenseHolder: sequence.licenseHolder,
                usageConstraints: sequence.usageConstraints,
                usageLimitation: sequence.usageLimitation,
            },
        });
    }

    async orderWithRemark(event: Event, entry: TypedSequenceMetadataDto, id: string) {
        event.stopPropagation();
        const audioLabel = await this.storeUtils.snapshot(selectAudioLabel);
        const selectedMedia = await this.storeUtils.snapshot(selectDetailMediaSelection);
        const selectionIds = await this.orderParamService.getSelectionIds();
        const selectedContent: OrderRemarkDialogContentAndConfig = {
            audioTrack: audioLabel,
            displayCheckBox: true,
        };
        this.confirmationDialogService
            .openRemarkDialog(selectedContent)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: OrderRemarkResponse) => {
                if (data) {
                    const item: AddShoppingCartEntryRequestDto = {
                        sequenceId: entry.sequenceId,
                        itemId: selectionIds.itemId,
                        programId: selectionIds.programId,
                        selectedAudioTrack: data.orderAudioOnly ? selectedMedia.audioTrack : undefined,
                        mediaCutId: data.orderAudioOnly ? selectedMedia.mediaCut : undefined,
                        remark: data.orderRemark,
                        ignoreUserRestrictions: false,
                        ignoreSizeLimit: false,
                    };
                    this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
                }
                focusElement(id);
            });
    }

    async addToShoppingCart(event: Event, entry: TypedSequenceMetadataDto, id: string) {
        event.stopPropagation();
        const selectionIds = await this.orderParamService.getSelectionIds();
        const item: AddShoppingCartEntryRequestDto = {
            sequenceId: entry.sequenceId,
            itemId: selectionIds.itemId,
            programId: selectionIds.programId,
            ignoreUserRestrictions: false,
            ignoreSizeLimit: false,
        };
        this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
        focusElement(id);
    }

    getKeyframeBlob(keyframeId: number): Observable<Blob | null> {
        return keyframeId > 0
            ? this.keyframeService.keyframesGet(keyframeId).pipe(catchError(_ => of(null)))
            : of(null);
    }

    getAccessibilityDurationString(duration: Duration): string {
        return duration !== null ? accessibilityDurationString(duration) : "unbekannt";
    }
}
