import { createAction, props } from '@ngrx/store';
import { ShoppingCartHitlistSortingAttribute, ShoppingCartHitlistSortingOrder } from './shopping-cart.state';
import { ShoppingCartDto } from '@faro/order-angular-client/model/shoppingCartDto';
import { HttpErrorResponse } from '@angular/common/http';
import {
    AddShoppingCartEntryRequestDto,
    AdmiraDeviceDto,
    AdmiraFolderResponse,
    CartEntryErrorDto,
    SearchResultDto,
    ShoppingCartStatus,
} from '@faro/order-angular-client';
import { MediaCutDto } from '@faro/metadata-angular-client';

export enum ShoppingCartActions {
    GET_ACTIVE_SHOPPING_CART = '[ShoppingCart] Get active shopping cart',
    GET_NEW_ACTIVE_SHOPPING_CART = '[ShoppingCart] Get newly created active shopping cart',
    SET_ACTIVE_SHOPPING_CART = '[ShoppingCart] Set active shopping cart',
    SET_NEW_ACTIVE_SHOPPING_CART = '[ShoppingCart] Set new active shopping cart',
    GET_SELECTED_SHOPPING_CART = '[ShoppingCart] Get selected shopping cart',
    SET_SELECTED_SHOPPING_CART = '[ShoppingCart] Set selected shopping cart',
    SET_SCROLL_POSITION = '[ShoppingCart] Set scroll position',
    RESET_ALL_SHOPPING_CART_SEARCH_OPTIONS = '[ShoppingCart] Reset all shopping cart search options',
    UPDATE_SHOPPING_CART_SEARCH_OPTIONS_INPUT_FIELDS = '[ShoppingCart] Update shopping cart search options input fields',
    UPDATE_SHOPPING_CART_SEARCH_OPTIONS_DATE_RANGE = '[ShoppingCart] Update shopping cart search options date range',
    UPDATE_SHOPPING_CART_SEARCH_OPTIONS_ORDER_STATUS = '[ShoppingCart] Update shopping cart search options order status',
    UPDATE_SHOPPING_CART_SEARCH_ORDER_BY = '[ShoppingCart] Update shopping cart search order by',
    UPDATE_SHOPPING_CART_SEARCH_ORDER_DIRECTION = '[ShoppingCart] Update shopping cart search order direction',
    UPDATE_SHOPPING_CART_EXPORT_DATE = '[ShoppingCart] Update shopping cart export date',
    UPDATE_SHOPPING_CART_EXPORT_EMAIL = '[ShoppingCart] Update shopping cart export email',
    SEARCH_SHOPPING_CARTS = '[ShoppingCart] Search shopping carts',
    INITIAL_SEARCH_SHOPPING_CARTS = '[ShoppingCart] Initial search shopping carts',
    SET_SHOPPING_CART_SEARCH_RESPONSE = '[ShoppingCart] set search shopping carts response',
    ORDER_ACTIVE_SHOPPING_CART = '[ShoppingCart] Order active shopping cart',
    DUPLICATE_SHOPPING_CART = '[ShoppingCart] Duplicate shopping cart',
    ACTIVATE_SHOPPING_CART = '[ShoppingCart] Activate shopping cart',
    CREATE_NEW_SHOPPING_CART = '[ShoppingCart] Create new shopping cart',
    DO_NO_SHOW_NEW_SHOPPING_CART_DIALOG = '[ShoppingCart] Do not show new shopping cart dialog',
    RENAME_ACTIVE_SHOPPING_CART = '[ShoppingCart] Rename active shopping cart',
    SET_ACTIVE_SHOPPING_CART_PID = '[ShoppingCart] Set PID of the active shopping cart',
    SET_ACTIVE_SHOPPING_CART_FID = '[ShoppingCart] Set FID of the active shopping cart',
    CHANGE_POSITION_OF_ACTIVE_SHOPPING_CART_ENTRY = '[ShoppingCart] Change position of active shopping cart entry',
    SET_ACTIVE_SHOPPING_CART_ENTRY_REMARK = '[ShoppingCart] Set remark of a shopping cart entry',
    DELETE_ACTIVE_SHOPPING_CART_ENTRY = '[ShoppingCart] Delete a shopping cart entry',
    SET_ENTRY_ERROR_MESSAGES = '[ShoppingCart] Sets entry error messages to display',
    GET_AVAILABLE_MATERIAL = '[ShoppingCart] Get available material for a shopping cart entry',
    SET_AVAILABLE_MATERIAL = '[ShoppingCart] Set available material for a shopping cart entry',
    SET_SELECTED_MATERIAL = '[ShoppingCart] Set selected material for a shopping cart entry',
    ADD_ITEM_TO_ACTIVE_SHOPPING_CART = '[ShoppingCart] Add item to active shopping cart',
    NOTIFY_ITEM_ADDED_SUCCESSFULLY = '[ShoppingCart] Added item successfully',
    NOTIFY_ITEM_ALREADY_EXISTS = '[ShoppingCart] Item already exists',
    NOTIFY_OF_SHOPPING_CART_CREATE_ERROR = '[ShoppingCart] Throw error creating new cart',
    NOTIFY_OF_SAVE_ACTIVE_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error saving cart information',
    NOTIFY_OF_GET_ACTIVE_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when active shopping cart data cannot be loaded',
    NOTIFY_OF_GET_SELECTED_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when selected shopping cart data cannot be loaded',
    NOTIFY_OF_ORDER_ACTIVE_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when active shopping cart cannot be ordered',
    NOTIFY_OF_DUPLICATE_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when shopping cart cannot be duplicated',
    NOTIFY_OF_ACTIVATE_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when shopping cart cannot be activated',
    NOTIFY_OF_SEARCH_SHOPPING_CART_ERROR = '[ShoppingCart] Throw error when search for shopping cart fails',
    NOTIFY_OF_ADMIRA_DEVICE_ERROR = '[ShoppingCart] Throw error when admira device error occurs',
    CALL_SUCCESS = '[ShoppingCart] Handles responses until Order Service is completed',
    GET_USER_EXPORT_TARGET = '[ShoppingCart] Get export target for user',
    GET_ADMIRA_DEVICES = '[ShoppingCart] Get admira devices for export',
    SET_ADMIRA_DEVICES = '[ShoppingCart] Set admira devices for export',
    GET_ADMIRA_FOLDERS = '[ShoppingCart] Get admira folders for export',
    SET_ADMIRA_FOLDERS = '[ShoppingCart] Set admira folders for export',
    SET_SELECTED_ADMIRA_DEVICE = '[ShoppingCart] Set selected admira device',
    STORE_SELECTED_ADMIRA_DEVICE = '[ShoppingCart] Store selected admira device',
    SET_ACTIVE_USER = '[ShoppingCart] Set active user',
}

/**
 * General actions
 * */

export const getActiveShoppingCart = createAction(ShoppingCartActions.GET_ACTIVE_SHOPPING_CART);

export const getNewActiveShoppingCart = createAction(ShoppingCartActions.GET_NEW_ACTIVE_SHOPPING_CART);

export const setActiveShoppingCart = createAction(
    ShoppingCartActions.SET_ACTIVE_SHOPPING_CART,
    props<{ activeShoppingCart: ShoppingCartDto }>()
);

export const setNewActiveShoppingCart = createAction(
    ShoppingCartActions.SET_NEW_ACTIVE_SHOPPING_CART,
    props<{ activeShoppingCart: ShoppingCartDto }>()
);

export const getSelectedShoppingCart = createAction(
    ShoppingCartActions.GET_SELECTED_SHOPPING_CART,
    props<{ cartId: string }>()
);

export const setSelectedShoppingCart = createAction(
    ShoppingCartActions.SET_SELECTED_SHOPPING_CART,
    props<{ selectedShoppingCart: ShoppingCartDto }>()
);

export const setScrollTopPosition = createAction(
    ShoppingCartActions.SET_SCROLL_POSITION,
    props<{ scrollTopPosition: number }>()
);

export const callSuccessAction = createAction(ShoppingCartActions.CALL_SUCCESS);

/**
 * Search options
 * */
export const resetAllShoppingCartSearchOptions = createAction(
    ShoppingCartActions.RESET_ALL_SHOPPING_CART_SEARCH_OPTIONS
);
export const updateShoppingCartSearchOptionsInputFields = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_SEARCH_OPTIONS_INPUT_FIELDS,
    props<{
        shoppingCartSearchOptions: {
            description: string;
            number: string;
            pid: string;
            firstName: string;
            lastName: string;
        };
    }>()
);

export const updateDateRange = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_SEARCH_OPTIONS_DATE_RANGE,
    props<{ from: Date | null; to: Date | null }>()
);

export const updateSelectedOrderStatus = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_SEARCH_OPTIONS_ORDER_STATUS,
    props<{ orderStatus: ShoppingCartStatus | null }>()
);

export const updateShoppingCartSearchOrderBy = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_SEARCH_ORDER_BY,
    props<{ orderBy: ShoppingCartHitlistSortingAttribute }>()
);

export const updateShoppingCartSearchOrderDirection = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_SEARCH_ORDER_DIRECTION,
    props<{ orderDirection: ShoppingCartHitlistSortingOrder }>()
);

export const searchShoppingCarts = createAction(ShoppingCartActions.SEARCH_SHOPPING_CARTS);

export const initialSearchShoppingCarts = createAction(ShoppingCartActions.INITIAL_SEARCH_SHOPPING_CARTS);

export const setShoppingCartSearchResponse = createAction(
    ShoppingCartActions.SET_SHOPPING_CART_SEARCH_RESPONSE,
    props<{ searchResult: SearchResultDto[] }>()
);

export const setActiveUserSearchOptions = createAction(
    ShoppingCartActions.SET_ACTIVE_USER,
    props<{ user: { firstName: string; lastName: string } }>()
);

/**
 * Export options
 * */

export const updateShoppingCartExportDate = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_EXPORT_DATE,
    props<{ exportDate: Date }>()
);
export const updateShoppingCartExportEmail = createAction(
    ShoppingCartActions.UPDATE_SHOPPING_CART_EXPORT_EMAIL,
    props<{ eMail: string }>()
);

export const renameActiveShoppingCart = createAction(
    ShoppingCartActions.RENAME_ACTIVE_SHOPPING_CART,
    props<{ name: string }>()
);

export const changeActiveShoppingCartProductionId = createAction(
    ShoppingCartActions.SET_ACTIVE_SHOPPING_CART_PID,
    props<{ pid: string }>()
);

export const changeActiveShoppingCartFassungsId = createAction(
  ShoppingCartActions.SET_ACTIVE_SHOPPING_CART_FID,
  props<{ fid: string }>()
);

export const getAdmiraDevices = createAction(ShoppingCartActions.GET_ADMIRA_DEVICES);

export const setAdmiraDevices = createAction(
    ShoppingCartActions.SET_ADMIRA_DEVICES,
    props<{ admiraDevices: AdmiraDeviceDto[] }>()
);

export const getAdmiraFolders = createAction(
    ShoppingCartActions.GET_ADMIRA_FOLDERS,
    props<{ deviceId: string; path: string }>()
);

export const setAdmiraFolders = createAction(
    ShoppingCartActions.SET_ADMIRA_FOLDERS,
    props<{ admiraFolders: AdmiraFolderResponse }>()
);

export const setSelectedAdmiraDevice = createAction(
    ShoppingCartActions.SET_SELECTED_ADMIRA_DEVICE,
    props<{ selectedAdmiraDevice: AdmiraDeviceDto; rootPath: string; rootName: string }>()
);

export const storeSelectedAdmiraDevice = createAction(
    ShoppingCartActions.STORE_SELECTED_ADMIRA_DEVICE,
    props<{ selectedAdmiraDevice: AdmiraDeviceDto; rootPath: string; rootName: string }>()
);

export const getUserExportTarget = createAction(ShoppingCartActions.GET_USER_EXPORT_TARGET);

/**
 * Shopping cart actions
 * */

export const orderActiveShoppingCart = createAction(ShoppingCartActions.ORDER_ACTIVE_SHOPPING_CART);

export const duplicateShoppingCart = createAction(
    ShoppingCartActions.DUPLICATE_SHOPPING_CART,
    props<{ cartId: string }>()
);

export const activateShoppingCart = createAction(
    ShoppingCartActions.ACTIVATE_SHOPPING_CART,
    props<{ cartId: string }>()
);

export const createNewShoppingCart = createAction(ShoppingCartActions.CREATE_NEW_SHOPPING_CART);

export const doNotShowNewCartDialog = createAction(ShoppingCartActions.DO_NO_SHOW_NEW_SHOPPING_CART_DIALOG);

/**
 * Shopping cart entry actions
 * */

export const changeActiveShoppingCartEntryPosition = createAction(
    ShoppingCartActions.CHANGE_POSITION_OF_ACTIVE_SHOPPING_CART_ENTRY,
    props<{ entryIds: string[] }>()
);

export const setActiveShoppingCartEntryRemark = createAction(
    ShoppingCartActions.SET_ACTIVE_SHOPPING_CART_ENTRY_REMARK,
    props<{ entryId: string; note: string }>()
);

export const deleteActiveShoppingCartEntry = createAction(
    ShoppingCartActions.DELETE_ACTIVE_SHOPPING_CART_ENTRY,
    props<{ entryId: string }>()
);

export const getAvailableMaterial = createAction(
    ShoppingCartActions.GET_AVAILABLE_MATERIAL,
    props<{ programId: string; itemId: string | undefined; sequenceId: string | undefined; selectionVtcIn: string | undefined; selectionVtcOut: string | undefined }>()
);

export const setAvailableMaterial = createAction(
    ShoppingCartActions.SET_AVAILABLE_MATERIAL,
    props<{ material: MediaCutDto[] }>()
);

export const setSelectedMaterial = createAction(
    ShoppingCartActions.SET_SELECTED_MATERIAL,
    props<{ entryId: string; materialIds: string[] }>()
);

export const setEntryErrorMessages = createAction(
    ShoppingCartActions.SET_ENTRY_ERROR_MESSAGES,
    props<{ cartEntryErrors: CartEntryErrorDto[] }>()
);

/**
 * Add to shopping cart
 * */

export const addItemToActiveShoppingCart = createAction(
    ShoppingCartActions.ADD_ITEM_TO_ACTIVE_SHOPPING_CART,
    props<{ params: AddShoppingCartEntryRequestDto }>()
);

export const notifyOfSuccessfullyAddedItem = createAction(ShoppingCartActions.NOTIFY_ITEM_ADDED_SUCCESSFULLY);

export const notifyOfItemAlreadyExists = createAction(ShoppingCartActions.NOTIFY_ITEM_ALREADY_EXISTS);

/**
 * Errors
 * */

export const notifyOfGetActiveShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_GET_ACTIVE_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfGetSelectedShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_GET_SELECTED_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfSaveShoppingCartInformationError = createAction(
    ShoppingCartActions.NOTIFY_OF_SAVE_ACTIVE_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfCreateShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_SHOPPING_CART_CREATE_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfShoppingCartOrderError = createAction(
    ShoppingCartActions.NOTIFY_OF_ORDER_ACTIVE_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfDuplicateShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_DUPLICATE_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfActivateShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_ACTIVATE_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfSearchShoppingCartError = createAction(
    ShoppingCartActions.NOTIFY_OF_SEARCH_SHOPPING_CART_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);

export const notifyOfAdmiraDeviceError = createAction(
    ShoppingCartActions.NOTIFY_OF_ADMIRA_DEVICE_ERROR,
    props<{ errorResponse: HttpErrorResponse }>()
);
